<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">B. Measuring the Atmospheric Pressure</h2>
      <p class="mb-3">a) Fill in the information below from the second part of the experiment:</p>

      <calculation-input
        v-model="inputs.h1right"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{h}_\text{1,r}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h1left"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{h}_\text{1,l}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h2right"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{h}_\text{2,r}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.h2left"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{h}_\text{2,l}\text{:}$"
        append-text="$\text{cm}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.TinC"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{t:}$"
        append-text="$^\circ\text{C}$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) What was the value of P<sub>atm</sub> that was determined in part C of the experiment?
      </p>

      <calculation-input
        v-model="inputs.Patm"
        class="mb-2 mt-2 pb-5"
        prepend-text="$\text{P}_\text{atm}\text{:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) Using the equations shown below, calculate P<sub>1</sub> and P<sub>2</sub> in order to
        determine &#x394;P.
      </p>

      <calculation-input
        v-model="inputs.P1"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{P}_1\text{:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.P2"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\text{P}_2\text{:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.deltaP"
        class="mb-2 mt-2 pb-1"
        prepend-text="$\Delta\text{P:}$"
        append-text="$\text{cm H}_2\text{O}$"
        dense
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'GasesReportSheetB',
  components: {
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        h1right: null,
        h1left: null,
        h2right: null,
        h2left: null,
        TinC: null,
        Patm: null,
        P1: null,
        P2: null,
        deltaP: null,
      },
    };
  },
};
</script>
